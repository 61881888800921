<template>
    <!--components/RenderDescription.vue-->
    <div ref="element_ref">
        <div v-if="(! is_client) && (result != '')" :class="props.css_classes + ' description'" v-html="result"></div>
        <ClientOnly>
            <div class="description read_only" :class="{'description_max_height': read_more_required_ref.valueOf()}" v-if="(is_client) && (removeHtml(props.description,'strict') != '')" >
                <QuillEditor 
                :readOnly="true" 
                disabled='disabled'
                v-model:content="props.description" 
                contentType="html" 
                theme="snow" 
                class="quill_editor class_des " 
                />
                <div class="read_more_container" v-if="read_more_required_ref.valueOf()" >
                    <div class="read_more_gradient_spacer"></div>
                    <div class="solid_white_bg"><a href="#" @click.stop.prevent="show_full_description">(more)</a></div>
                    <div style="flex-grow: 1; background-color: white;">&nbsp;</div>
                </div>
            </div>
        </ClientOnly>

    </div>
    
</template>
<script setup>

/*
The idea behind this component is to make it safe to render the description or any HTML.
We can specify whether we want to truncate the string to certain length, and add CSS classes 
(typically set a max-height, etc).  If we do not specify the css_classes, it defaults to 
description_sanitized_default, which may not be ideal.  
Do not edit this class inside style.css, because that may
impact different places.  Create a separate CSS class for different situation, and specify 
the css_classes prop for that situation.

Perhaps we should change the name of this component to RenderHTML to make it more generic, 
but the tag in the template section above is p, so we will have to change that.  Also, I 
am not sure whether this component is better than the standard Vue way of handling / escaping 
HTML, so I am keeping the name of this component as RenderDescription for now until we have 
the need to support HTML in other fields and have more time to discuss on the proper way to 
handle HTML (sanitized HTML)
*/
const props = defineProps({
    'max_chars': {
        'type': Number,
        'required': false,
        'default': 999
    },
    'truncate': {
        'type': Boolean,
        'required': false,
        'default': false
    },
    'description': {
        'type': String,
        'required': false,
        'default': ''
    },
    'mode': {
        'type': String,
        'required': true,
        'default': 'strict'
    },
    'css_classes': {
        'type': String,
        'required': false,
        'default': 'description_sanitized_default'
    },
    'show_full_description_by_default': {
        type: Boolean,
        required: false,
        default: true
    }
});

let element_ref = ref(null);
let mode = props.mode || "strict";
if (props.truncate) {
    // We can only truncate if the mode is set to strict.  Otherwise, we will end up with invalid HTML,
    // and that may break the DOM, and the application may not work.
    mode = 'strict';
}

mode = ''; // Ignore the prop.

let result = removeHtml(props.description, mode);
result = result.replaceAll('&nbsp;','');

let read_more_required_ref = ref(false);

/*
On the server side, we call removeHtml, and do the truncate, and display the result for SEO purpose,
but on the browser side, we display the full description using the Quill editor assuming that it has
protection against XSS attacks.  On the browser side, initially, we hide part of the description using
CSS, but when the user clicks on the gradient area, we display the full description by removing the
CSS class, and hiding the "gradient" DIV.
*/

if (props.truncate) {
    let original_length = result.length;
    if (original_length > props.max_chars) {
        result = result.substr(0, props.max_chars) + '...';
    }
}

if (props.show_full_description_by_default == false) {
    let original_length = result.length;
    if (original_length > props.max_chars) {
        read_more_required_ref.value = true;
    }
}
const show_full_description = (event) => {
    read_more_required_ref.value = false;
}

let is_client = false;
if (typeof(window) != 'undefined') {
    is_client = true;
}

const swagger_schema_approach_b = () => {
    if ((element_ref.value) && (element_ref.value.querySelector)) {
        let schema_element_inside_description = element_ref.value.querySelector('.swagger_schema');
        if (schema_element_inside_description) {
            let schema_json_str = schema_element_inside_description.innerText;
            let schema_json = JSON.parse(JSON.stringify(jsyaml.load(schema_json_str)));
            let newElement = document.createElement("div");
            newElement.classList.add("swagger_schema_table_container_outside_of_description")
            element_ref.value.after(newElement)


            let properties = {}
            if ((schema_json.hasOwnProperty('items')) && (schema_json.items.hasOwnProperty('properties'))) {
                properties = schema_json.items.properties;
            } else if (schema_json.hasOwnProperty('properties')) {
                properties = schema_json.properties
            }


            if (Object.keys(properties).length > 0) {
                //let table_element = document.createElement("table");
                //newElement.appendChild(table_element);
                $(newElement).hide();
                //table_element.classList.add("swagger_table_outside_of_description")
                //let thead_element = document.createElement("thead");

                $(`
                <table class="swagger_table_outside_of_description">
                    <tbody></tbody>
                </table>
                `).appendTo(newElement)

                let tbody_elemement = newElement.querySelector('tbody');


                let keys = Object.keys(properties);
                keys.forEach((item_label) => {
                    let item = properties[item_label];

                    let tr_element = document.createElement("tr");
                    tbody_elemement.appendChild(tr_element)

                    let item_label_td_element = document.createElement('td');  // Name
                    item_label_td_element.innerText = item_label;
                    tr_element.appendChild(item_label_td_element);

                    let item_str = "";

                    if (item.hasOwnProperty('type')) {
                        item_str = item_str + item.type 
                        if (item.hasOwnProperty('format')) {
                            item_str = item_str + ` ($${item.format})`;
                        }
                    }
                    
                    if (item.hasOwnProperty('nullable')) {
                        item_str = item_str + "<br/><span class='swagger_italic'>nullable: " + item.nullable + "</span>"
                    }

                    if (item.hasOwnProperty('enum')) {
                        item_str = item_str + "<br/>Enum:<br/>" + JSON.stringify(item.enum)
                    }

                    if (item.hasOwnProperty('description')) {
                        item_str = item_str + "<br/>" + item.description;
                    }

                    if (item.hasOwnProperty('example')) {
                        item_str = item_str + "<br/><span class='swagger_example'>example: " + item.example + "</span>"
                    }

                    let item_other_info_td_element = document.createElement('td');  // Name
                    item_other_info_td_element.innerHTML = item_str;
                    tr_element.appendChild(item_other_info_td_element);
                })
                $(newElement).show();
            }
        }
    } else {
        setTimeout(swagger_schema_approach_b, 1000)
    }
}


onMounted(async () => { 
    setTimeout(swagger_schema_approach_b,1000)
})

/*
*/

</script>

<style scoped>
.read_only .ql-editor {
    background-color: transparent !important;
}
</style>